import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Invoice, Order } from '@alliance-disposal/transport-types';
import { Loading, Tooltip } from '@wayste/sour-ui';
import { formatServiceAddress, getPrimaryCustomerContact, moneyFormatter, round } from '@wayste/utils';
import { calculateOrderTotal, sortByKey } from '@wayste/utils';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import format from 'date-fns/format';
import { useHistory, useParams } from 'react-router-dom';
import CustomPricing from '../../../components/CustomPricing/CustomPricing';
import CustomerDetailsPartial from '../../../components/CustomerDetails';
import DetailsCardWrapper from '../../../components/DetailsCardWrapper';
import { paymentMethods } from '../../../utils';
import UniversalServicesDetails from '../../site-services/components/UniversalServicesDetails';
import CustomerCards from './customer-cards';
import CustomerInvoices from './customer-invoices';
import CustomerOrders from './customer-orders';
import CustomerPayments from './customer-payments';

export type AllianceOrderWithReceivables = Order.AllianceOrderTransport & {
    receivables: Invoice.ReceivableTransport[];
    payables: Invoice.PayableTransport[];
    orderTotal?: number;
    quoted?: number;
};

export type PaymentRowType = {
    amount: number;
    paymentReceivedDate: string;
    paymentIdentifierString: string;
    orderNumber?: string;
    invoiceNumber: string;
    address: string;
};

const CustomerDetailsContainer = () => {
    const client = useWaysteClient();
    const history = useHistory();
    const { id }: { id: string } = useParams();

    // STATE
    const [paymentRowsData, setPaymentRowsData] = useState<PaymentRowType[]>([]);

    const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport | null>(null);
    const [customerOrders, setCustomerOrders] = useState<AllianceOrderWithReceivables[]>([]);
    const [customerOrderCount, setCustomerOrderCount] = useState<number>(0);
    const [tonSpread, setTonSpread] = useState<number>(0);
    const [customerRevenue, setCustomerRevenue] = useState<number>(0);
    const [orderDataLoading, setOrderDataLoading] = useState(true);
    ///////////////////////////////////////////////////////
    // FUNCTION SECTION
    ///////////////////////////////////////////////////////

    const getOrdersWithInvoice = async () => {
        if (!customer) return [];
        const orderData = await client.order().adminPortal.query({
            allianceCustomerID: customer.id,
            limit: 1000,
        });

        setCustomerOrderCount(orderData.total);

        if (!orderData.results.length) return [];

        const orderIds = orderData.results.map((order) => order.id);

        const receivables = await client.invoice().adminPortal.receivable.query({
            orderID: orderIds.join(','),
            limit: 10000,
        });

        const payables = await client.invoice().adminPortal.payable.query({
            orderID: orderIds.join(','),
            limit: 10000,
        });

        const mapped = orderData.results.flatMap((order) => {
            const orderReceivables = receivables.results.filter((receivable) => receivable.invoiceDetails.orderID === order.id);
            const orderPayables = payables.results.filter((payable) => payable.invoiceDetails.orderID === order.id);
            return {
                ...order,
                receivables: orderReceivables,
                payables: orderPayables,
            };
        });
        return mapped as AllianceOrderWithReceivables[];
    };

    const setupOrderCustomer = async () => {
        if (!customer) return;
        setOrderDataLoading(true);
        const newCustomer = customer;
        const ordersResponse = await getOrdersWithInvoice();
        const { results: invoices } = await getCustomerPayments();

        let spread = 0;
        let spreadCount = 0;
        const orders: AllianceOrderWithReceivables[] = [];
        ordersResponse.forEach((order: AllianceOrderWithReceivables) => {
            if (order.actualWeightDumped) {
                const actualWeightDumped = order.actualWeightDumped.value > 0 ? order.actualWeightDumped.value : 0;
                const weightLimitValue = order.weightLimit?.value;

                if (actualWeightDumped !== undefined && weightLimitValue !== undefined) {
                    spread = round(spread + (actualWeightDumped - weightLimitValue));
                    spreadCount++;
                }
            }
            const orderTotal = calculateOrderTotal(order.receivables);
            const quoted = order.receivables?.find((invoice) =>
                invoice.invoiceDetails.lineItems?.find((lineItem: Invoice.LineItemTransport) => lineItem.itemName === 'QP-Haul'),
            )?.invoiceDetails.total;
            orders.push({ ...order, orderTotal, quoted });
        });

        if (spreadCount > 0) {
            setTonSpread(parseFloat((spread / spreadCount).toFixed(2)));
        }

        const sortedOrders = sortByKey('orderNumber', orders, true);
        const customerGMV = await client.invoice().adminPortal.gmvSummary({ customerID: customer?.id }); //fetch GMV
        setCustomerRevenue(customerGMV.reduce((acc, cur) => acc + (cur?.profit || 0), 0));
        setCustomerOrders(sortedOrders);
        setNewCustomer(newCustomer, invoices);
        setOrderDataLoading(false);
    };

    const getCustomerPayments = async () => {
        const payments = await client.invoice().adminPortal.receivable.query({
            customerID: customer?.id,
            hasPayments: true,
            sortDescending: true,
            limit: 10000,
        });
        return payments;
    };
    const setNewCustomer = (newCustomer: Customer.AllianceCustomerTransport, invoices: Invoice.ReceivableTransport[]) => {
        const paymentRows: PaymentRowType[] = [];
        invoices.forEach((invoice: Invoice.ReceivableTransport) => {
            invoice.invoiceDetails.payments?.forEach((payment: Invoice.PaymentTransport) => {
                paymentRows.push({
                    orderNumber: invoice.invoiceDetails.orderNumber,
                    invoiceNumber: invoice.invoiceDetails.invoiceNumber || '',
                    amount: payment.amount,
                    paymentReceivedDate: payment.paymentReceivedDate || payment.date,
                    paymentIdentifierString: payment.paymentMethod === 'REVENUE_SHARE' 
                        ? paymentMethods.revenueShare
                        : payment.paymentIdentifier
                            ? `${paymentMethods[payment.paymentMethod as keyof typeof paymentMethods] || payment.paymentMethod} ${payment.paymentIdentifier}`
                            : 'N/A',
                    address: formatServiceAddress(invoice.invoiceDetails.orderServiceLocation?.address) || '',
                });
            });
        });

        setPaymentRowsData(sortByKey('orderNumber', paymentRows, true));
        setCustomer(newCustomer);
    };

    const handleBackButtonClick = () => {
        history.goBack();
    };

    ///////////////////////////////////////////////////////
    // HOOKS SECTION
    ///////////////////////////////////////////////////////

    useEffect(() => {
        if (customer) {
            setupOrderCustomer();
        }
    }, [customer?.id]);

    useEffect(() => {
        if (id) {
            const sub = client.customer().adminPortal.subscriptions.fetch(id);
            sub.receive.subscribe({
                next: (customer) => {
                    setCustomer(customer);
                },
                error: (err) => {
                    console.error(err);
                    setCustomer(null);
                },
            });

            return () => sub.unsubscribe();
        }
    }, [id]);

    ///////////////////////////////////////////////////////
    // RENDER SECTION
    ///////////////////////////////////////////////////////

    if (!customer) {
        return (
            <div className="flex size-full items-center justify-center">
                <Loading size="h-12 w-12" />
            </div>
        );
    }

    const creditLimitColor = () => {
        if (!customer.balanceLimit) return 'text-gray-500';

        const balanceRatio = customer.balance || 0 / customer.balanceLimit;

        if (balanceRatio > 1) {
            return 'text-red-500';
        } else if (balanceRatio > 0.75) {
            return 'text-orange-500';
        } else {
            return 'text-green-500';
        }
    };

    const topCardDetails = [
        { label: 'Account #', value: customer.customerNumber },
        { label: 'Revenue', value: moneyFormatter(customerRevenue) },
        {
            label: 'Balance',
            value: orderDataLoading ? (
                'Loading...'
            ) : customer.balanceLimit ? (
                <span className={creditLimitColor()}>
                    {moneyFormatter(customer.balance || 0)} / {moneyFormatter(customer.balanceLimit)}
                </span>
            ) : (
                moneyFormatter(customer.balance || 0)
            ),
        },
        { label: 'Account Credit', value: moneyFormatter(customer.accountCredit) },
        { label: 'Order Count', value: orderDataLoading ? 'Loading...' : customerOrderCount },
        {
            label: (
                <Tooltip text="Only orders with tons dumped and ton limit">
                    <span>Avg Ton Spread</span>
                </Tooltip>
            ),
            value: orderDataLoading ? (
                'Loading...'
            ) : (
                <span className={tonSpread > 0 ? 'text-red-500' : tonSpread < 0 ? 'text-success' : ''}>
                    {tonSpread > 0 ? '+' : ''}
                    {tonSpread}
                </span>
            ),
        },
        {
            label: 'Last Ordered',
            value: customerOrders[0] ? format(new Date(customerOrders[0].expectedDeliveryDate), 'MM/dd/yy') : 'N/A',
        },
    ];

    return (
        <div className="flex-1 bg-gray-50">
            <div className="container mx-auto flex flex-col gap-y-3 p-6 pt-5">
                <div className="flex items-center">
                    <button className="btn-icon" type="button" onClick={handleBackButtonClick}>
                        <ChevronLeftIcon className="size-5" />
                    </button>
                    <h5 className="text-xl">Customer Details</h5>
                </div>
                <DetailsCardWrapper
                    heading={
                        customer.companyName ||
                        getPrimaryCustomerContact(customer)?.firstName + ' ' + getPrimaryCustomerContact(customer)?.lastName
                    }
                    chips={
                        customer.doNotService
                            ? [{ label: 'Do Not Service', primaryColor: 'error-light', textColor: 'error-dark', size: 'small' }]
                            : []
                    }
                >
                    <div className="flex gap-2">
                        {topCardDetails.map((item, index) => (
                            <div key={item.label + String(index)} className="border-r border-r-gray-200 px-4 text-center">
                                <div>
                                    <div className="mb-2 text-gray-700">{item.label}</div>
                                    <div>{item.value}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </DetailsCardWrapper>
                <CustomerDetailsPartial customer={customer} edit={true} />
                <CustomPricing customer={customer} />
                <CustomerCards customer={customer} />
                <CustomerInvoices customer={customer} />
                {orderDataLoading ? (
                    <div className="flex justify-center">
                        <Loading />
                    </div>
                ) : (
                    <CustomerOrders customer={customer} orders={customerOrders} />
                )}
                <CustomerPayments customer={customer} payments={paymentRowsData} />
                {customer.id && <UniversalServicesDetails customerID={customer.id} />}
            </div>
        </div>
    );
};

export default CustomerDetailsContainer;
