import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { DayOfWeek, UniversalService } from '@alliance-disposal/transport-types';
import {
    AutoComplete,
    AutoCompleteOption,
    Button,
    Checkbox,
    CurrencyTextField,
    DatePicker,
    Select,
    SelectOption,
    TextField,
    Tooltip,
} from '@wayste/sour-ui';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { endOfYesterday } from 'date-fns';
import { Controller, FieldPath, get, useFieldArray, useFormContext } from 'react-hook-form';
import { UIContext } from '../../../contexts';
import { UniversalServiceFormData } from '../UniversalServiceCreate/UniversalServiceCreate';
import { defaultEvent } from './site-service-defaults';

interface OneTimeServiceOrderSubFormProps {
    serviceFamily?: UniversalService.ServiceTypeFamily;
    mode: 'create' | 'update';
}

const OneTimeServiceOrderSubForm = ({ serviceFamily, mode }: OneTimeServiceOrderSubFormProps) => {
    const index = 0; // TODO: Remove this when we have multiple service events for one time groupings
    const client = useWaysteClient();
    const { showFlash, godModeActive } = useContext(UIContext);
    const [serviceTypeFamilies, setServiceTypeFamilies] = useState<UniversalService.ServiceTypeFamily[]>([]);
    const [selectedServiceTypeFamily, setSelectedServiceTypeFamily] = useState<UniversalService.ServiceTypeFamily | undefined>(undefined);
    const [selectedSku, setSelectedSku] = useState<UniversalService.ServiceType | undefined>(undefined);
    const [allowRecurringQuantity, setAllowRecurringQuantity] = useState<boolean>(false);

    useEffect(() => {
        client
            .universalService()
            .serviceFamily.query({ orderBy: 'name' })
            .then((data) => {
                setServiceTypeFamilies(data.results);
            })
            .catch((err) => {
                console.error('error fetching service types', err);
            });
    }, []);

    const {
        control,
        setValue,
        formState: { errors },
    } = useFormContext<UniversalServiceFormData>();
    const { fields, append, remove } = useFieldArray({
        control,
        name: `serviceGrouping.serviceOrders.${index}.serviceEvents`,
    });

    const onServiceFamilySelect = (id: string) => {
        const family = serviceTypeFamilies.find((item) => item.id === id);

        const lineItemMap = new Map<UniversalService.DefaultLineItemType, UniversalService.ServiceLineItemType>();

        family?.lineItemTypes.forEach((item) => {
            if (UniversalService.DefaultLineItemTypes.includes(item.description as UniversalService.DefaultLineItemType)) {
                lineItemMap.set(item.description as UniversalService.DefaultLineItemType, item);
            }
        });

        const recurring = lineItemMap.get('recurring');

        if (!recurring) {
            showFlash('This Service type is not set up correctly and cannot be used. Please contact an administrator.', 'warning');
            setSelectedServiceTypeFamily(undefined);
            return;
        }
        setValue(`serviceGrouping.serviceOrders.${index}.serviceEvents.${0}.lineItemTypeID`, recurring.id);

        if (recurring.minQuantity === 0) {
            setValue(`serviceGrouping.serviceOrders.${index}.serviceEvents.${0}.priceQuantity`, 1);
            setAllowRecurringQuantity(false);
        } else if (recurring.minQuantity > 0) {
            setValue(`serviceGrouping.serviceOrders.${index}.serviceEvents.${0}.priceQuantity`, recurring.minQuantity);
            setAllowRecurringQuantity(true);
        }
        setSelectedServiceTypeFamily(family);
    };

    const handleSkuSelect = (id: string) => {
        const sku = selectedServiceTypeFamily?.serviceTypes.find((item) => item.id === id);
        setSelectedSku(sku || undefined);
    };

    useEffect(() => {
        if (!serviceFamily) return;
        setSelectedServiceTypeFamily(serviceFamily);
    }, [serviceFamily]);

    return (
        <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
            <div className="flex w-full justify-between border-b border-gray-300 md:col-span-2">
                <div className="text-l pb-2 ">Service Order Details</div>
                <Tooltip text="This will create multiple service orders under the same grouping with the same details. Please use it carefully.">
                    <InformationCircleIcon className="size-6 text-gray-400" />
                </Tooltip>
            </div>
            <Select
                label="Select Service Type"
                onSelect={onServiceFamilySelect}
                defaultValue={''}
                disabled={mode === 'update' && !godModeActive}
                required
                value={selectedServiceTypeFamily?.id}
            >
                {serviceTypeFamilies.map((ServiceTypeFamily, index) => (
                    <SelectOption key={'serviceTypeFamily' + index} value={ServiceTypeFamily.id}>
                        {ServiceTypeFamily.name}
                    </SelectOption>
                ))}
            </Select>
            <Controller
                name={
                    mode === 'create'
                        ? `serviceGrouping.serviceOrders.${index}.serviceTypeID`
                        : (`serviceGrouping.serviceOrders.${index}.serviceType.id` as FieldPath<UniversalServiceFormData>)
                }
                control={control}
                render={({ field }) => {
                    return (
                        <AutoComplete
                            error={get(errors, '')}
                            label="Select SKU"
                            onSelect={(value) => {
                                field.onChange(value);
                                handleSkuSelect(value);
                            }}
                            defaultValue={''}
                            disabled={!selectedServiceTypeFamily || (mode === 'update' && !godModeActive)}
                            required
                            value={(field.value as string) || ''}
                        >
                            {selectedServiceTypeFamily?.serviceTypes.map((serviceType) => (
                                <AutoCompleteOption key={serviceType.id} value={serviceType.id}>
                                    {serviceType.name}
                                </AutoCompleteOption>
                            )) || [
                                <AutoCompleteOption key="default" value="">
                                    Select an option
                                </AutoCompleteOption>,
                            ]}
                        </AutoComplete>
                    );
                }}
            />
            {selectedSku?.setupType === 'NO_ASSET_DELIVERED' ? (
                <>
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.startDate`}
                        rules={{
                            required: {
                                value: true,
                                message: 'Service Date is required',
                            },
                        }}
                        render={({ field }) => (
                            <DatePicker
                                value={field.value ? new Date(field.value) : ''}
                                onChange={(date) => {
                                    field.onChange(date);
                                }}
                                minDate={mode === 'update' ? undefined : endOfYesterday()}
                                label="Service Date"
                                required
                                closeOnSelect
                                clearable
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.deliveryNotes`}
                        defaultValue={''}
                        render={({ field }) => (
                            <TextField
                                type={'string'}
                                label="Service Notes"
                                error={get(errors, `serviceGrouping.serviceOrders.${index}.deliveryNotes`)}
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                </>
            ) : (
                <>
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.startDate`}
                        render={({ field }) => (
                            <DatePicker
                                value={field.value ? new Date(field.value) : ''}
                                onChange={(date) => field.onChange(date)}
                                minDate={mode === 'update' ? undefined : endOfYesterday()}
                                label="Start Date"
                                required
                                closeOnSelect
                                clearable
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.endDate`}
                        render={({ field }) => (
                            <DatePicker
                                value={field.value ? new Date(field.value) : ''}
                                onChange={(date) => field.onChange(date)}
                                minDate={mode === 'update' ? undefined : endOfYesterday()}
                                label="End Date"
                                closeOnSelect
                                clearable
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.deliveryDate`}
                        render={({ field }) => (
                            <DatePicker
                                value={field.value ? new Date(field.value) : ''}
                                onChange={(date) => field.onChange(date)}
                                minDate={mode === 'update' ? undefined : endOfYesterday()}
                                label="Delivery Date"
                                helperText="Only use delivery date if there is being a physical asset delivered. If the service is being performed on-site (i.e bulk pickup), leave this blank."
                                closeOnSelect
                                clearable
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.deliveryNotes`}
                        defaultValue={''}
                        render={({ field }) => (
                            <TextField
                                type={'string'}
                                label="Delivery Notes"
                                error={get(errors, `serviceGrouping.serviceOrders.${index}.deliveryNotes`)}
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.removalDate`}
                        render={({ field }) => (
                            <DatePicker
                                value={field.value ? new Date(field.value) : undefined}
                                onChange={(date) => field.onChange(date)}
                                minDate={mode === 'update' ? undefined : endOfYesterday()}
                                label="Removal Date"
                                closeOnSelect
                                clearable
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.removalNotes`}
                        defaultValue={''}
                        render={({ field }) => (
                            <TextField
                                type={'string'}
                                label="Removal Notes"
                                error={get(errors, `serviceGrouping.serviceOrders.${index}.removalNotes`)}
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                </>
            )}
            <div className="md:col-span-2">
                <Controller
                    control={control}
                    name={`serviceGrouping.serviceOrders.${index}.vendorOnlyNotes`}
                    defaultValue={''}
                    render={({ field }) => (
                        <TextField
                            type={'string'}
                            label="Vendor only Notes"
                            helperText="Notes only the Hauler can see"
                            error={get(errors, 'vendorNotes')}
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
            </div>
            {selectedSku?.setupType === 'NO_ASSET_DELIVERED' ? null : (
                <>
                    <Controller
                        name={`serviceGrouping.serviceOrders.${index}.serviceFrequency`}
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Service Frequency is required',
                            },
                            min: {
                                value: 0,
                                message: 'Service Frequency must be at least 0',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                type={'number'}
                                label="Service Frequency"
                                error={fieldState.error}
                                required
                                helperText="If the service type does not have regularly schedule service leave this at 0"
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                    <Controller
                        name={`serviceGrouping.serviceOrders.${index}.serviceFrequencyUnit`}
                        control={control}
                        render={({ field }) => (
                            <Select
                                error={get(errors, `serviceGrouping.serviceOrders.${index}.serviceFrequencyUnit`)}
                                label="Service Frequency Unit"
                                onSelect={field.onChange}
                                defaultValue={''}
                                required
                                value={field.value}
                            >
                                {Object.entries(UniversalService.ServiceFrequencyUnitLabels).map((unit) => (
                                    <SelectOption key={'serviceFrequencyUnit' + unit[0]} value={unit[0]}>
                                        {unit[1]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                    <Controller
                        name={`serviceGrouping.serviceOrders.${index}.serviceDays`}
                        control={control}
                        render={({ field }) => (
                            <Select
                                multiple
                                error={get(errors, `serviceGrouping.serviceOrders.${index}.servicedDays`)}
                                label="Serviced on which day(s) of the week"
                                onSelect={field.onChange}
                                defaultValue={''}
                                value={field.value}
                            >
                                {Object.entries(DayOfWeek).map((option: [string, string], index: number) => (
                                    <SelectOption key={'servicedDays' + index} value={option[0]}>
                                        {option[1]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                    <div className="hidden md:block" />
                </>
            )}
            <Controller
                control={control}
                name={`serviceGrouping.serviceOrders.${index}.poNumber`}
                defaultValue={''}
                render={({ field }) => (
                    <TextField
                        type={'string'}
                        label="PO Number"
                        helperText="If the customer has a Purchase Order (PO) number."
                        error={get(errors, `serviceGrouping.serviceOrders.${index}.poNumber`)}
                        inputProps={{
                            ...field,
                        }}
                    />
                )}
            />
            <div className="hidden md:block" />
            <div className="md:col-span-2">
                <Controller
                    control={control}
                    name={`serviceGrouping.serviceOrders.${index}.internalNotes`}
                    defaultValue={''}
                    render={({ field }) => (
                        <TextField
                            type={'string'}
                            label="Internal Notes"
                            helperText="Notes only Sourgum team members can see"
                            error={get(errors, `serviceGrouping.serviceOrders.${index}.internalNotes`)}
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
            </div>
            {selectedSku?.setupType === 'NO_ASSET_DELIVERED' ? null : (
                <>
                    <div className="text-l border-b border-gray-300 pb-2 md:col-span-2">Rental Period Details</div>
                    <Controller
                        name={`serviceGrouping.serviceOrders.${index}.originalRentalPeriod`}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                type={'number'}
                                label="Rental Period"
                                error={get(errors, `serviceGrouping.serviceOrders.${index}.originalRentalPeriod`)}
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                    <Controller
                        name={`serviceGrouping.serviceOrders.${index}.rentalPeriodUnit`}
                        control={control}
                        render={({ field }) => (
                            <Select
                                error={get(errors, 'serviceGrouping.serviceOrders.${index}.rentalPeriodUnit')}
                                label="Select Rental Period Unit"
                                onSelect={field.onChange}
                                defaultValue={''}
                                required
                                value={field.value}
                            >
                                {UniversalService.RentalPeriodUnits.map((unit, index) => (
                                    <SelectOption key={'rentalPeriodUnits' + index} value={unit}>
                                        {unit}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                    <Controller
                        name={`serviceGrouping.serviceOrders.${index}.rentalExtensionFee`}
                        control={control}
                        render={({ field }) => (
                            <CurrencyTextField
                                type={'string'}
                                label="Rental Extension Fee"
                                error={get(errors, `serviceGrouping.serviceOrders.${index}.rentalExtensionFee`)}
                                value={field.value ? field.value.toString() : ''}
                                onChange={field.onChange}
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                    <hr className="md:col-span-2" />
                </>
            )}
            {mode === 'create' && (
                <>
                    <Controller
                        control={control}
                        name={`quantity.${index}`}
                        defaultValue={1}
                        render={({ field }) => (
                            <TextField
                                type={'number'}
                                label="Quantity of this sku with these details"
                                helperText="This will create multiple service orders under the same grouping with the same details. Please use it carefully."
                                required
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                    <div className="hidden md:block" />
                    <div className="text-l border-b border-gray-300 pb-2 md:col-span-2">Pricing Info</div>
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${0}.unitPrice`}
                        defaultValue={0}
                        rules={{
                            required: {
                                value: true,
                                message: 'Unit Price is required',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <CurrencyTextField
                                label="Unit Price"
                                error={fieldState.error}
                                useCents
                                value={field.value}
                                onChange={field.onChange}
                                required
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${0}.priceQuantity`}
                        defaultValue={0}
                        render={({ field }) => (
                            <TextField
                                label="Quantity"
                                type="number"
                                error={get(errors, `serviceGrouping.serviceOrders.${index}.serviceEvents.${0}.priceQuantity`)}
                                inputProps={{
                                    ...field,
                                    disabled: !allowRecurringQuantity,
                                }}
                            />
                        )}
                    />
                    {selectedServiceTypeFamily && (
                        // TODO Add Total For Line Item
                        <>
                            {fields.slice(1).map((item, lineItemIndex) => (
                                <div key={item.id} className="md:col-span-2">
                                    <div className="flex space-x-2 align-middle">
                                        <div>
                                            <Controller
                                                name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${
                                                    lineItemIndex + 1
                                                }.lineItemTypeID`}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        error={get(
                                                            errors,
                                                            `serviceGrouping.serviceOrders.${index}.serviceEvents.${
                                                                lineItemIndex + 1
                                                            }.lineItemTypeID`,
                                                        )}
                                                        label="Line Item Name"
                                                        onSelect={field.onChange}
                                                        defaultValue={''}
                                                        value={field.value}
                                                    >
                                                        {selectedServiceTypeFamily?.lineItemTypes.map((lineItem, index) => (
                                                            <SelectOption key={'lineItem' + index} value={lineItem.id}>
                                                                {lineItem.description}
                                                            </SelectOption>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </div>
                                        <div className="w-1/5">
                                            <Controller
                                                control={control}
                                                name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${lineItemIndex + 1}.description`}
                                                defaultValue={''}
                                                render={({ field }) => (
                                                    <TextField
                                                        type={'string'}
                                                        label="Description"
                                                        error={get(errors, `serviceEvents.${index}.${lineItemIndex + 1}.description`)}
                                                        inputProps={{
                                                            ...field,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="w-1/5">
                                            <Controller
                                                control={control}
                                                name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${lineItemIndex + 1}.priceQuantity`}
                                                defaultValue={1}
                                                render={({ field }) => (
                                                    <TextField
                                                        required
                                                        type={'number'}
                                                        label="Quantity"
                                                        error={get(errors, `serviceEvents.${index}.${lineItemIndex + 1}.quantity`)}
                                                        inputProps={{
                                                            ...field,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="w-1/5">
                                            <Controller
                                                control={control}
                                                name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${lineItemIndex + 1}.unitPrice`}
                                                defaultValue={0}
                                                render={({ field }) => (
                                                    <CurrencyTextField
                                                        required
                                                        label="Rate"
                                                        error={get(errors, `serviceEvents.${index}.${lineItemIndex + 1}.unitPrice`)}
                                                        value={(+field.value / 100).toString()}
                                                        onChange={(value) => field.onChange(+value * 100)}
                                                        inputProps={{
                                                            ...field,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="mt-2 w-1/5">
                                            <Controller
                                                name={`serviceGrouping.serviceOrders.${index}.serviceEvents.${lineItemIndex + 1}.taxable`}
                                                control={control}
                                                defaultValue={false}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        error={get(
                                                            errors,
                                                            `serviceGrouping.serviceOrders.${index}.serviceEvents.${lineItemIndex + 1}.taxable`,
                                                        )}
                                                        label="Taxable"
                                                        inputProps={{
                                                            value: 'type',
                                                            checked: field.value,
                                                            onChange: () => field.onChange(!field.value),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <Button
                                        className="btn-error-text-only ml-2 mt-2 whitespace-nowrap"
                                        onClick={() => remove(lineItemIndex + 1)}
                                        startIcon={<XMarkIcon />}
                                    >
                                        Remove line item
                                    </Button>
                                </div>
                            ))}
                            <div className="px-2 pb-4">
                                <Button
                                    className="btn-secondary-text-only whitespace-nowrap"
                                    onClick={() => append(defaultEvent)}
                                    startIcon={<PlusIcon />}
                                >
                                    Add additional line item
                                </Button>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default OneTimeServiceOrderSubForm;
